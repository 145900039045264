@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --polar-green-1: #f6ffed;
    --polar-green-2: #52c41a;
    --polar-green-3: #d9f7be;
    --gold-1: #fffbe6;
    --hero-section: #c7deff;
    --primary-light: #f0f7ff;
    --primary-blue: #c7deff;
    --primary-6: #235df4;
    --primary-selected: #1677ff;
    --primary-color-icon: #000000;
    --secondary-color-icon: #ffffff;
    --rr--fill-on-color: #fadb14;
  }
  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --hero-section: #1e2a3a;
    --primary-light: #1e2a3a;
    --primary-color-icon: #ffffff;
    --secondary-color-icon: #000000;
    --polar-green-1: #ddffbc;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer utilities {
  .popover-full-width {
    width: var(--radix-popover-trigger-width);
    max-height: var(--radix-popover-content-available-height);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #e5e7eb;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9ca3af;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #6b7280;
  }

  /* Dark Mode Scrollbar */
  .dark ::-webkit-scrollbar-track {
    background-color: #1f2937;
  }

  .dark ::-webkit-scrollbar-thumb {
    background-color: #4b5563;
  }

  .dark ::-webkit-scrollbar-thumb:hover {
    background-color: #9ca3af;
  }

  .star-app-store .rr--hf-svg-on {
    fill: #fadb14 !important;
    stroke: #fadb14 !important;
  }

  .star-app-store .rr--on .rr--svg {
    fill: #fadb14 !important;
    stroke: #fadb14 !important;
  }

  .star-app-store .rr--off .rr--svg {
    stroke: #fadb14 !important;
  }
}

::placeholder {
  @apply text-base;
}
